function Contact(){
    return (
        <div>
        <h2>Contact</h2>
        <p className = 'main-text'>To discuss further details about putting your student in tutoring, feel free to contact me at jon@gibbs.tk. 
        </p>
        </div>
    );
}
export default Contact;

